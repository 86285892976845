import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Popover,
  Select,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { API } from "../../config/Api";
import { DELETE, GET, POST } from "../../utils/apiRequest";
import Loading from "../../components/loading";
import { useNavigate } from "react-router-dom";
import PageHeaders from "../../components/pageHeaders";
import { CiSearch } from "react-icons/ci";
import { useForm } from "antd/es/form/Form";
import JobFormModal from "./form";
import JobDataTable from "./dataTable";
import HorizontalLineLoader from "../components/loader";
import "./style.scss";
import { IoReload } from "react-icons/io5";
import BulkCreateModal from "./bulkCreateModal";
import { RiErrorWarningLine } from "react-icons/ri";
import moment from "moment";

const JobScreen = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { Option } = Select;
  const [Notifications, contextHolder] = notification.useNotification();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);

  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [hasActiveJobsToday, setHasActiveJobsToday] = useState(false);

  const [page, setPage] = useState(1);
  const [take, setTake] = useState(20);
  const [meta, setMeta] = useState<any>({});

  const [activities, setActivities] = useState([]);
  const [activities2, setActivities2] = useState([]);
  const [staff, setStaff] = useState([]);
  const [staff2, setStaff2] = useState([]);
  const [bulkCreatModal, setBulkCraeteModal] = useState(false);

  useEffect(() => {
    getJob();
    getAllActivities();
    getActivities();
    getAllStaff();
    getStaff();
  }, [page, take]);

  const getJob = async () => {
    try {
      setIsLoading2(true);
      let obj = {
        staffId: form.getFieldValue("staffId"),
        activities: form.getFieldValue("activities"),
        startDate: form.getFieldValue("date"),
        query: form.getFieldValue("query"),
        page: page,
        take: take,
      };
      let api = API.JOBS_LIST;
      const response: any = await POST(api, obj);
      if (response?.status) {
        const today = moment().startOf("day");
        const yesterday = moment().subtract(1, "days").startOf("day");
        const filteredData = response.data.filter(
          (item: any) =>
            item.status !== false &&
            (moment(item.createdAt).isSame(today, "day") ||
              moment(item.createdAt).isSame(yesterday, "day"))
        );
        setHasActiveJobsToday(filteredData.length > 0);
        setData(response?.data);
        setIsLoading(false);
        setMeta(response?.meta);
      } else {
        setData([]);
        setIsLoading(false);
      }
      setIsLoading2(false);
    } catch (err) {
      setIsLoading(false);
      setIsLoading2(false);
    }
  };

  const getAllActivities = async () => {
    try {
      const activityDet: any = await GET(API.ACTIVITY_PICKER);
      if (activityDet.status) {
        setActivities(activityDet.data);
      } else {
        Notifications["error"]({
          message: "No Activities",
        });
      }
    } catch (err) {
      console.log("err", err);
      Notifications["error"]({
        message: "Network error.",
      });
    }
  };
  const getActivities = async () => {
    try {
      const activityDet: any = await GET(API.ACTIVITY_PICKER2);
      if (activityDet.status) {
        setActivities2(activityDet.data);
      } else {
        Notifications["error"]({
          message: "No Activities",
        });
      }
    } catch (err) {
      console.log("err", err);
      Notifications["error"]({
        message: "Network error.",
      });
    }
  };

  const getAllStaff = async () => {
    try {
      const staffDet: any = await GET(API.STAFFS_PICKER);
      if (staffDet.status) {
        setStaff(staffDet.data);
      } else {
        Notifications["error"]({
          message: "No Staffs",
        });
      }
    } catch (err) {
      console.log("err", err);
      Notifications["error"]({
        message: "Network error.",
      });
    }
  };
  const getStaff = async () => {
    try {
      const staffDet: any = await GET(API.STAFFS_PICKER2);
      if (staffDet.status) {
        setStaff2(staffDet.data);
      } else {
        Notifications["error"]({
          message: "No Staffs",
        });
      }
    } catch (err) {
      console.log("err", err);
      Notifications["error"]({
        message: "Network error.",
      });
    }
  };

  const deleteJob = async (id: any) => {
    try {
      setIsLoading2(true);
      let api = API.JOBS_DELETE + id;
      let Delete: any = await DELETE(api);
      if (Delete?.status) {
        setIsLoading2(false);
        Notifications["success"]({
          message: "Job Deleted",
        });
        getJob();
      } else {
        setIsLoading2(false);
        Notifications["error"]({
          message: "Job not deleted",
        });
      }
    } catch (err) {
      setIsLoading2(false);
      Notifications["error"]({
        message: "Network error. Job not deleted",
      });
    }
  };

  const editUser = (user: any) => {
    try {
      setSelectedItem(user);
      setModalVisible(!modalVisible);
    } catch (err) {
      console.log(err);
    }
  };

  const onView = (val: any) => {
    try {
      navigate(`/Auth/jobDetails/${val}`, { state: { val } });
    } catch (err) {
      console.log(err);
    }
  };

  const content = (
    <div
      style={{
        display: "flex",
        gap: 5,
      }}
    >
      <RiErrorWarningLine color="orange" size={20} />
      <div className="popoverContentTxt">
        Please clock out all the active jobs for today or yesterday before
        proceeding
      </div>
    </div>
  );

  return (
    <div>
      {contextHolder}
      <PageHeaders title={"Jobs"} backButton={true} loading={isLoading2}>
        <Form form={form} onValuesChange={getJob}>
          <div className="jobScreen-PageHeaderBox">
            <Form.Item name={"query"} noStyle>
              <Input
                style={{ width: 200 }}
                placeholder="Search"
                allowClear
                suffix={<CiSearch size={16} color="#000" />}
              />
            </Form.Item>
            <Form.Item name={"staffId"} noStyle>
              <Select
                allowClear
                showSearch={true}
                placeholder={"Select Staff"}
                defaultValue={"Select Staff"}
              >
                {staff?.map((option: any) => (
                  <Option key={option.id} value={option.id}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={"activities"} noStyle>
              <Select
                allowClear
                showSearch={true}
                placeholder={"Select Activities"}
                defaultValue={"Select Activities"}
              >
                {activities?.map((option: any, i: any) => (
                  <Option key={option.id} value={option.id}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={"date"} noStyle>
              <DatePicker placeholder="Create Date" style={{ minWidth: 100 }} />
            </Form.Item>
            <Form.Item noStyle>
              <Button onClick={() => editUser({})} type="primary">
                Create +
              </Button>
            </Form.Item>
            {/* {hasActiveJobsToday ? (
              <Popover placement="bottomRight" content={content}>
                <Button type="primary">Bulk Create</Button>
              </Popover>
            ) : (
              <Button type="primary" onClick={() => setBulkCraeteModal(true)}>
                Bulk Create
              </Button>
            )} */}
            <Button className="refreshBtn" onClick={() => getJob()}>
              <IoReload color="#000" size={15} />
            </Button>
          </div>
        </Form>
      </PageHeaders>
      {isLoading2 ? <HorizontalLineLoader /> : null}
      <div className="screenBox">
        <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <JobDataTable
              data={data}
              onEdit={(item: any) => editUser(item)}
              onView={(item: any) => onView(item)}
              delete={(id: any) => deleteJob(id)}
              meta={meta}
              onPageChange={(page: number, pageSize: number) => {
                setIsLoading2(true);
                setPage(page);
                setTake(pageSize);
              }}
            />
          )}
        </Card>
      </div>
      {modalVisible ? (
        <JobFormModal
          staff={staff2}
          activities={activities2}
          data={selectedItem}
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          onSuccess={() => {
            editUser({});
            getJob();
          }}
        />
      ) : null}
      {bulkCreatModal ? (
        <BulkCreateModal
          staff={staff}
          activities={activities}
          visible={bulkCreatModal}
          onCancel={() => setBulkCraeteModal(false)}
          data={data}
          onSuccess={() => {
            getJob();
            setBulkCraeteModal(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default JobScreen;
