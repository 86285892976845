import React, { useState } from "react";
import { Button } from "antd";
import { MdOutlineFileDownload } from "react-icons/md";
import { Container } from "react-bootstrap";
import "./styles.scss";

const Downloads = () => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = `public/app/ticketing-1.0.6.apk`;
    link.setAttribute("download", "teenzania-1.0.6.apk");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="">
      <Container>
        <div className="download-box">
          <div>
            <b>Download Ticketing App Version 1.0.6</b>
          </div>
          <br />
          <a
            href={`${process.env.PUBLIC_URL}/teenzania-1.0.6.apk`}
            download="teenzania-1.0.6.apk"
          >
            <Button type="primary">
              <MdOutlineFileDownload size={20} />
              &nbsp; Download
            </Button>
          </a>
        </div>
      </Container>
    </div>
  );
};

export default Downloads;
